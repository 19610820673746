import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-native-calendars';
import { DateData, Direction } from 'react-native-calendars/src/types';
import DefaultIcon from './DefaultIcon';

import Moment from 'moment';
Moment.locale('es');

import { LocaleConfig } from 'react-native-calendars';
LocaleConfig.locales['es'] = {
  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 
    'Octubre', 'Noviembre', 'Diciembre'],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul.', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  today: 'Hoy'
};
LocaleConfig.defaultLocale = 'es';

const DateSelect = (props: any) => {
    const [date, setDate] = useState<Date>(props.value || new Date());

    useEffect(() => {
        props.onChange(date);
    }, [date]);
    
    return (
        <Calendar
            // Initially visible month. Default = now
            current={props.value}
            // Handler which gets executed on day press. Default = undefined
            onDayPress={(value: DateData) => setDate(new Date(value.year, value.month - 1, value.day))}
            // Month format in calendar title. Formatting values: http://arshaw.com/xdate/#Formatting
            monthFormat={'MMMM yyyy'}
            // Hide month navigation arrows. Default = false
            hideArrows={false}
            // Do not show days of other months in month page. Default = false
            hideExtraDays={false}
            // If hideArrows = false and hideExtraDays = false do not switch month when tapping on greyed out
            // day from another month that is visible in calendar page. Default = false
            disableMonthChange={false}
            // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday
            firstDay={0}
            // Hide day names. Default = false
            hideDayNames={false}
            // Show week numbers to the left. Default = false
            showWeekNumbers={false}
            // Disable left arrow. Default = false
            disableArrowLeft={false}
            // Disable right arrow. Default = false
            disableArrowRight={false}
            // Disable all touch events for disabled days. can be override with disableTouchEvent in markedDates
            disableAllTouchEventsForDisabledDays={false}
            // Enable the option to swipe between months. Default = false
            enableSwipeMonths={false}
            // Replace default arrows with custom ones (direction can be 'left' or 'right')
            renderArrow={(direction: Direction) => {
                if (direction === 'left') {
                    return (
                        <DefaultIcon 
                            icon="chevron-back-outline" 
                            size={20}
                            color="steelblue" />
                    );
                }
                else if (direction === 'right') {
                    return (
                        <DefaultIcon 
                            icon="chevron-forward-outline" 
                            size={20}
                            color="steelblue" />
                    );
                }
                else {
                    return (null);
                }
            }}
            markedDates={{
                [Moment(date).format("YYYY-MM-DD")]: {
                  selected: true,
                  disableTouchEvent: false,
                },
            }}
            theme={{
                selectedDayBackgroundColor: 'steelblue',
                todayTextColor: 'steelblue',
            }} />
    );
};

export default DateSelect;
