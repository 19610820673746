import ApiService from "../services/ApiService";
import CompanyModel from "./CompanyModel";

export default class CustomerModel {
    id: number;
    company_id: number;
    company: CompanyModel;
    name: string;
    last_name: string;
    email: string;
    membership_number: string;

    constructor(id: number, company_id: number, company: CompanyModel, name: string, 
        last_name: string, email: string, membership_number: string) {
        this.id = id;
        this.company_id = company_id;
        this.company = company;
        this.name = name;
        this.last_name = last_name;
        this.email = email;
        this.membership_number = membership_number;
    };

    public static getAll = (company_id: number, token: string) : Promise<CustomerModel[]> => {
        return new Promise((resolve, reject) => {
            ApiService.get('customer/' + company_id.toString(), token).then((response: any) => {
                resolve(response.map((response: any) => new CustomerModel(response.id, response.company_id, 
                    new CompanyModel(response.company.id, response.company.name, response.company.logo), 
                    response.user.name, response.user.last_name, response.user.email, 
                    response.membership_number)));
            }, (error: string) => {
                reject(error);
            });
        });
    };

    public static save = (company_id: number, token: string, customer: CustomerModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (customer.id === 0) {
                ApiService.post('customer/' + company_id.toString(), customer, token).then(() => {
                    resolve();
                }, (error: string) => {
                    reject(error);
                });
            }
            else {
                ApiService.put('customer/' + company_id.toString() + "/" + customer.id.toString(), customer, token).then(() => {
                    resolve();
                }, (error: string) => {
                    reject(error);
                });
            }
        });
    };

    public static delete = (company_id: number, token: string, customer_id: number) : Promise<void> => {
        return new Promise((resolve, reject) => {
            ApiService.delete('customer/' + company_id.toString() + "/" + customer_id.toString(), token).then(() => {
                resolve();
            }, (error: string) => {
                reject(error);
            });
        });
    };
};
