import 'react-native-gesture-handler';
import React, { useEffect, useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Platform } from 'react-native';
import { NavigationContainer, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as RootNavigation from './RootNavigation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DefaultIcon from './components/DefaultIcon';
import DangerIconButton from './components/DangerIconButton';
import PrimaryIconButton from './components/PrimaryIconButton';
import QueryString from 'query-string';
import Loader from './components/Loader';
import { Buffer } from 'buffer';

import Login from './pages/Login';
import Recover from './pages/Recover';
import Main from './pages/Main';
import Users from './pages/Users';
import User from './pages/User';
import Customers from './pages/Customers';
import Customer from './pages/Customer';
import Exercises from './pages/Exercises';
import Exercise from './pages/Exercise';
import Routines from './pages/Routines';
import Routine from './pages/Routine';
import ChangePassword from './pages/ChangePassword';
import MainCustomer from './pages/MainCustomer';

import UserModel from './models/UserModel';
import CompanyModel from './models/CompanyModel';
import Logo from './components/Logo';

const Stack = createNativeStackNavigator();

export default function App(props: any) {
  const [user, setUser] = useState<UserModel|null>(null);
  const [token, setToken] = useState<string|null>(null);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  const [changePasswordUser, setChangePasswordUser] = useState<UserModel|null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const onLogin = (user: UserModel, token: string) : void => {
    setLoading(true);
    AsyncStorage.setItem('@user', JSON.stringify(user)).then(() => {
      AsyncStorage.setItem('@token', token).then(() => {
        setToken(token);
        setUser(user);
        setLoading(false);
      });
    });
  };

  const onLogout = () : void => {
    setLoading(true);
    AsyncStorage.removeItem('user').then(() => {
      AsyncStorage.removeItem('token').then(() => {
        setToken(null);
        setUser(null);
        setLoading(false);
      });
    });
  };

  const onChangePassword = (token: string = '') : void => {
    if (!isChangePassword) {
      AsyncStorage.setItem('token', token).then(() => {
        setToken(token);
      });
    }
    else {
      window.location.href = "/";
      setIsChangePassword(false);
      setChangePasswordUser(null);
    }
  };

  const openMenu = () : void => {
    RootNavigation.navigate('main');
  };

  const openCustomerMenu = () : void => {
    RootNavigation.navigate('main-customer');
  };

  useEffect(() => {
    setLoading(true);

    AsyncStorage.getItem('user').then((value: string|null) => {
      setUser(value ? JSON.parse(value) : null);
      AsyncStorage.getItem('token').then((value: string|null) => {
        setToken(value);

        if (Platform.OS === 'web') {
          const qs = QueryString.parse(location.search);
          if (qs !== null) {
            if (qs.action !== null && qs.action === "cambiar-contrasena") {
              if (qs.t !== null) {
                const t = Buffer.from(qs.t.toString(), 'base64')
                  .toString('ascii')
                  .split(':');
                setIsChangePassword(true);
                setChangePasswordUser(new UserModel(parseInt(t[1]), parseInt(t[0]), 
                  new CompanyModel(parseInt(t[0]), '', ''), '', '', t[2], ''));
              }
            }
          }
          setLoading(false);
        }
        else {
          setLoading(false);
        }
      });
    });
  }, []);

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      <View style={styles.content}>
        {
          !loading ? (
            !isChangePassword ? (
              !user ? (
                <NavigationContainer theme={DarkTheme}>
                  <Stack.Navigator initialRouteName='login'>
                    <Stack.Screen
                      name='login'
                      options={{ 
                        title: 'Iniciar sesión',
                        headerLeft: () => <DefaultIcon icon="log-in-outline" color="#fff" size={24} />,
                      }}>
                      {(props: any) => <Login {...props} onSuccess={(user: UserModel, token: string) => onLogin(user, token)} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='recover'
                      options={{ 
                        title: 'Recuperar contraseña',
                        headerLeft: () => <DefaultIcon icon="lock-closed-outline" color="#fff" size={24} />,
                      }}>
                      {(props: any) => <Recover {...props} />}
                    </Stack.Screen>
                  </Stack.Navigator>
                </NavigationContainer>
              ) : (user.profile === "A") ? (
                <NavigationContainer 
                  theme={DarkTheme}
                  ref={RootNavigation.navigationRef}>
                  <Stack.Navigator 
                    initialRouteName='main'
                    screenOptions={{
                      headerRight: () => <View style={styles.menu}>
                        <PrimaryIconButton 
                          onPress={() => openMenu()} 
                          icon="menu-outline" 
                          size={24} />
                        <DangerIconButton 
                          onPress={() => onLogout()} 
                          icon="power-outline" 
                          size={24} />
                      </View>,
                    }}>
                    <Stack.Screen
                      name='main'
                      options={{ 
                        title: 'Inicio',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <Main {...props} user={user} onLogout={() => onLogout()} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='users'
                      options={{
                        title: 'Usuarios',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <Users {...props} user={user} token={token} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='user'
                      options={{
                        title: 'Usuario',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <User {...props} user={user} token={token} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='customers'
                      options={{
                        title: 'Clientes',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <Customers {...props} user={user} token={token} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='customer'
                      options={{
                        title: 'Usuario',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <Customer {...props} user={user} token={token} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='exercises'
                      options={{
                        title: 'Ejercicios',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <Exercises {...props} user={user} token={token} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='exercise'
                      options={{
                        title: 'Ejercicio',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <Exercise {...props} user={user} token={token} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='routines'
                      options={{
                        title: 'Rutinas',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <Routines {...props} user={user} token={token} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='routine'
                      options={{
                        title: 'Rutina',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <Routine {...props} user={user} token={token} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='change-password'
                      options={{
                        title: 'Cambiar contraseña',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <ChangePassword {...props} user={user} token={token} onSuccess={(token: string) => onChangePassword(token)} />}
                    </Stack.Screen>
                  </Stack.Navigator>
                </NavigationContainer>
              ) : (
                <NavigationContainer 
                  theme={DarkTheme}
                  ref={RootNavigation.navigationRef}>
                  <Stack.Navigator 
                    initialRouteName='main-customer'
                    screenOptions={{
                      headerRight: () => <View style={styles.menu}>
                        <PrimaryIconButton 
                          onPress={() => RootNavigation.navigate('main-customer')} 
                          icon="home-outline" 
                          size={24} />
                        <PrimaryIconButton 
                          onPress={() => RootNavigation.navigate('change-password')} 
                          icon="lock-closed-outline" 
                          size={24} />
                        <DangerIconButton 
                          onPress={() => onLogout()} 
                          icon="power-outline" 
                          size={24} />
                      </View>,
                    }}>
                    <Stack.Screen
                      name='main-customer'
                      options={{ 
                        title: 'Inicio',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <MainCustomer {...props} user={user} token={token} onLogout={() => onLogout()} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name='change-password'
                      options={{
                        title: 'Cambiar contraseña',
                        headerLeft: () => <Logo source={user.company.logo} />,
                      }}>
                      {(props: any) => <ChangePassword {...props} user={user} token={token} onSuccess={(token: string) => onChangePassword(token)} />}
                    </Stack.Screen>
                  </Stack.Navigator>
                </NavigationContainer>
              )
            ) : (
              <NavigationContainer theme={DarkTheme}>
                  <Stack.Navigator initialRouteName='change-password'>
                    <Stack.Screen
                      name='change-password'
                      options={{
                        title: 'Cambiar contraseña',
                        headerLeft: () => <DefaultIcon icon="lock-closed-outline" color="#fff" size={24} />,
                      }}>
                      {(props: any) => <ChangePassword {...props} user={changePasswordUser} onSuccess={() => onChangePassword()} />}
                    </Stack.Screen>
                  </Stack.Navigator>
                </NavigationContainer>
            )
          ) : (
            <Loader />
          )
        }
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000',
  },
  content: {
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
  },
  menu: {
    flexDirection: 'row',
  },
});
