import ApiService from "../services/ApiService";
import CompanyModel from "./CompanyModel";

export default class ExerciseModel {
    id: number;
    company_id: number;
    company: CompanyModel;
    name: string;
    description: string;
    video: string;

    constructor(id: number, company_id: number, company: CompanyModel, name: string, 
        description: string, video: string) {
        this.id = id;
        this.company_id = company_id;
        this.company = company;
        this.name = name;
        this.description = description;
        this.video = video;
    };

    public static getAll = (company_id: number, token: string) : Promise<ExerciseModel[]> => {
        return new Promise((resolve, reject) => {
            ApiService.get('exercise/' + company_id.toString(), token).then((response: any) => {
                resolve(response.map((response: any) => new ExerciseModel(response.id, response.company_id, 
                    new CompanyModel(response.company.id, response.company.name, response.company.logo), 
                    response.name, response.description, response.video)));
            }, (error: string) => {
                reject(error);
            });
        });
    };

    public static save = (company_id: number, token: string, exercise: ExerciseModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (exercise.id === 0) {
                ApiService.post('exercise/' + company_id.toString(), exercise, token).then(() => {
                    resolve();
                }, (error: string) => {
                    reject(error);
                });
            }
            else {
                ApiService.put('exercise/' + company_id.toString() + "/" + exercise.id.toString(), exercise, token).then(() => {
                    resolve();
                }, (error: string) => {
                    reject(error);
                });
            }
        });
    };

    public static delete = (company_id: number, token: string, exercise_id: number) : Promise<void> => {
        return new Promise((resolve, reject) => {
            ApiService.delete('exercise/' + company_id.toString() + "/" + exercise_id.toString(), token).then(() => {
                resolve();
            }, (error: string) => {
                reject(error);
            });
        });
    };
};
