import React from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';

const DefaultContent = (props: any) => {
  return (
    <View style={styles.content}>
      <View style={styles.container}>
        {props.children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
    content: {
      padding: 10,
    },
    container: {
      width: '100%',
    },
});

export default DefaultContent;
