import React, { useEffect, useState } from 'react';
import { View, VirtualizedList } from 'react-native';
import Content from '../components/Content';
import DateSelect from '../components/DateSelect';
import Loader from '../components/Loader';
import WarningAlert from '../components/WarningAlert';
import LinkListItem from '../components/LinkListItem';
import VideoModal from '../components/VideoModal';
import Label from '../components/Label';

import RoutineModel from '../models/RoutineModel';
import RoutineExerciseModel from '../models/RoutineExerciseModel';
import ExerciseModel from '../models/ExerciseModel';

import Moment from 'moment';
Moment.locale('es');

const MainCustomer = (props: any) => {
  const [date, setDate] = useState<Date>(new Date());
  const [routine, setRoutine] = useState<RoutineModel|null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [exercise, setExercise] = useState<ExerciseModel|null>(null);

  const Item = (item: any) : JSX.Element => {
    return (
      <LinkListItem
        title={item.item.exercise.name}
        text={item.item.series + " series de " + item.item.repetitions + " repeticiones - " + item.item.description}
        onPress={() => {setExercise(item.item.exercise)}} />
    );
  };

  useEffect(() => {
    setLoading(true);
    RoutineModel.getByUser(props.user.company_id, props.user.id, props.token, date)
      .then((routine: RoutineModel|null) => {
        setRoutine(routine);
        setLoading(false);
      });
  }, [date]);

  return (
    <Content>
      <View style={{minWidth: '100%', width: '100%'}}>
        <DateSelect 
          onChange={(date: Date) => setDate(date)}
          value={date} />
        {
          !loading ? (
            <View style={{marginTop: 20}}>
              {
                routine ? (
                  <>
                    <Label text={"Rutina del día " + Moment(date).format("DD-MM-YYYY")} />
                    <VideoModal 
                      video={exercise?.video}
                      open={exercise !== null}
                      onRequestClose={() => {setExercise(null)}}
                      description={exercise?.description} />
                    {
                      routine?.routine_exercises.length ? (
                        <VirtualizedList
                          data={routine?.routine_exercises}
                          initialNumToRender={10}
                          renderItem={({ item }) : JSX.Element => <Item item={item} />}
                          keyExtractor={(item: RoutineExerciseModel) : string => item.id.toString()}
                          getItemCount={(data: RoutineExerciseModel[]) : number => data.length}
                          getItem={(data: RoutineExerciseModel[], index: number) : RoutineExerciseModel => data[index]} />
                      ) : (
                        <WarningAlert text="No se han encontrado ejercicios asociados a la rutina de la fecha seleccionada." />
                      )
                    }
                  </>
                ) : (
                  <WarningAlert text="No se ha encontrado una rutina para la fecha seleccionada." />
                )
              }
            </View>
          ) : (
            <Loader />
          )
        }
      </View>
    </Content>
  );
};

export default MainCustomer;
