import React, { useEffect, useState } from 'react';
import { VirtualizedList } from 'react-native';
import DangerButton from '../components/DangerButton';
import Loader from '../components/Loader';
import SecondaryButton from '../components/SecondaryButton';
import SuccessButton from '../components/SuccessButton';
import DangerModal from '../components/DangerModal';
import DateInput from '../components/DateInput';
import MultilineInput from '../components/MultilineInput';
import DropDown from '../components/DropDown';
import Label from '../components/Label';
import IntegerInput from '../components/IntegerInput';
import PrimaryButton from '../components/PrimaryButton';
import WarningAlert from '../components/WarningAlert';
import RemovableListItem from '../components/RemovableListItem';
import Content from '../components/Content';

import RoutineModel from '../models/RoutineModel';
import CustomerModel from '../models/CustomerModel';
import ExerciseModel from '../models/ExerciseModel';
import RoutineExerciseModel from '../models/RoutineExerciseModel';

import Moment from 'moment';
Moment.locale('es');

const Routine = (props: any) => {
  const _DEFAULT_ROUTINE_EXERCISE = new RoutineExerciseModel(0, props.route.params.routine.id, 
    null, null, null, null, '');

  const [routine, setRoutine] = useState<RoutineModel>(Object.assign({}, props.route.params.routine));
  const [customers, setCustomers] = useState<CustomerModel[]>(new Array<CustomerModel>());
  const [exercises, setExercises] = useState<ExerciseModel[]>(new Array<ExerciseModel>());
  const [routineExercise, setRoutineExercise] = useState<RoutineExerciseModel>(_DEFAULT_ROUTINE_EXERCISE);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const addRoutineExercise = () : void => {
    setRoutine({
      ...routine,
      routine_exercises: [
        ...routine.routine_exercises, 
        routineExercise,
      ],
    });
    setRoutineExercise({
      ...routineExercise,
      exercise_id: null,
      exercise: null,
      series: null,
      repetitions: null,
      description: '',
    });
  };

  const removeRoutineExercise = (value: RoutineExerciseModel) : void => {
    setRoutine({
      ...routine,
      routine_exercises: routine.routine_exercises.filter(re => re !== value),
    });
  };

  const RoutineExerciseItem = (item: any) : JSX.Element => {
    return (
      <RemovableListItem
        title={item.item.exercise?.name}
        text={item.item.series + " serie/s de " + item.item.repetitions + " repeticion/es"}
        disabled={loading}
        onRemovePress={() => removeRoutineExercise(item.item)} />
    );
  };

  const onSave = () : void => {
    setLoading(true);
    RoutineModel.save(props.user.company_id, props.token, routine).then(() => {
      props.navigation.navigate("main");
      props.navigation.navigate("routines");
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  const onDelete = () : void => {
    setLoading(true);
    RoutineModel.delete(props.user.company_id, props.token, routine.id).then(() => {
      props.navigation.navigate("main");
      props.navigation.navigate("routines");
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    CustomerModel.getAll(props.user.company_id, props.token).then((customers: CustomerModel[]) => {
      setCustomers(customers);
      ExerciseModel.getAll(props.user.company_id, props.token).then((exercises: ExerciseModel[]) => {
        setExercises(exercises);
        setLoading(false);
      }, (error: string) => {
        setError(error);
        setLoading(false);
      });
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });

    return function cleanup() {
      setCustomers(new Array<CustomerModel>());
      setExercises(new Array<ExerciseModel>());
    };
  }, []);

  return (
    <Content>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <DateInput
              autoFocus={false}
              editable={!loading}
              placeholder="Fecha"
              value={routine.date}
              onChange={(value: Date) => setRoutine({
                ...routine,
                date: Moment(value).format("YYYY-MM-DD")
              })} />
            <DropDown
              editable={!loading}
              onChange={(value: string) => setRoutine({
                ...routine,
                customer_id: parseInt(value),
                customer: customers.find(c => c.id == parseInt(value)) || null,
              })}
              value={routine.customer_id || 0}
              placeholder="Cliente"
              items={customers.map(c => {
                return {
                  label: c.last_name + ", " + c.name,
                  value: c.id,
                };
              })} />
            <MultilineInput
              autoFocus={false}
              editable={!loading}
              placeholder="Descripción"
              value={routine.description}
              onChange={(value: string) => setRoutine({ ...routine, description: value })} />
            <Label text="Ejercicios" />
            <DropDown
              editable={!loading}
              onChange={(value: string) => setRoutineExercise({
                ...routineExercise,
                exercise_id: parseInt(value),
                exercise: exercises.find(e => e.id == parseInt(value)) || null,
              })}
              value={routineExercise.exercise_id || 0}
              placeholder="Ejercicio"
              items={exercises
                .filter(e => !routine.routine_exercises.find(re => re.exercise_id == e.id))
                .map(e => {
                  return {
                    label: e.name,
                    value: e.id,
                  };
                })
              } />
            <IntegerInput
              autoFocus={false}
              editable={!loading}
              placeholder="Series"
              value={routineExercise.series || ""}
              onChange={(value: number) => setRoutineExercise({ ...routineExercise, series: value })} />
            <IntegerInput
              autoFocus={false}
              editable={!loading}
              placeholder="Repeticiones"
              value={routineExercise.repetitions || ""}
              onChange={(value: number) => setRoutineExercise({ ...routineExercise, repetitions: value })} />
            <MultilineInput
              autoFocus={false}
              editable={!loading}
              placeholder="Descripción"
              value={routineExercise.description}
              onChange={(value: string) => setRoutineExercise({ ...routineExercise, description: value })} />
            <PrimaryButton
              text="Agregar ejercicio"
              icon="checkmark-outline"
              disabled={loading || !routineExercise.exercise_id || !routineExercise.series || !routineExercise.repetitions}
              onPress={() => addRoutineExercise()} />
            {
              routine.routine_exercises.length ? (
                <VirtualizedList
                  data={routine.routine_exercises}
                  initialNumToRender={10}
                  renderItem={({ item }) : JSX.Element => <RoutineExerciseItem item={item} />}
                  keyExtractor={(item: RoutineExerciseModel) : string => item.exercise_id?.toString() || ""}
                  getItemCount={(data: RoutineExerciseModel[]) : number => data.length}
                  getItem={(data: RoutineExerciseModel[], index: number) : RoutineExerciseModel => data[index]} />
              ) : (
                <WarningAlert text="No hay ejercicios agregados a la rutina." />
              )
            }
            <SecondaryButton
              text="Volver"
              icon="chevron-back-outline"
              disabled={loading}
              onPress={() => props.navigation.navigate("routines")} />
            <SuccessButton
              text="Guardar cambios"
              icon="checkmark-outline"
              disabled={loading || !routine.date || !routine.customer_id || 
                !routine.routine_exercises.length}
              onPress={() => onSave()} />
            {
              (routine.id !== 0) ? (
                <DangerButton
                  text="Eliminar"
                  icon="close-outline"
                  disabled={loading}
                  onPress={() => onDelete()} />
              ) : (
                null
              )
            }
          </>
        ) : (
          <Loader />
        )
      }
    </Content>
  );
};

export default Routine;
