import React from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';

const Content = (props: any) => {
  return (
    <ScrollView style={styles.content}>
      <View style={styles.container}>
        {props.children}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
    content: {
      
    },
    container: {
      flex: 1,
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: 10,
    },
});

export default Content;
