import React, { useState } from 'react';
import { StyleSheet, View, Modal } from 'react-native';
import YoutubePlayer from 'react-native-youtube-iframe';
import DangerButton from './DangerButton';
import DarkLabel from './DarkLabel';
import WarningAlert from './WarningAlert';

const VideoModal = (props: any) => {
    const [error, setError] = useState<boolean>(false);

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={props.open}
            onRequestClose={props.onRequestClose}>
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    {
                        !error ? (
                            <YoutubePlayer
                                height={250}
                                width={400}
                                play={true}
                                videoId={props.video}
                                onChangeState={() => {}}
                                onReady={() => {}}
                                onError={() => setError(true)} />
                        ) : (
                            <WarningAlert text="Lo sentimos! Ha ocurrido un error al cargar el video. Por favor, intente nuevamente." />
                        )
                    }
                    {
                        props.description ? (
                            <DarkLabel text={props.description} />
                        ) : (
                            null
                        )
                    }
                    <DangerButton 
                        text="Cerrar"
                        disabled={false}
                        onPress={props.onRequestClose} />
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    modalView: {
        margin: 10,
        backgroundColor: "white",
        borderRadius: 10,
        padding: 10,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 5,
        elevation: 5,
        minWidth: 300,
    },
});

export default VideoModal;
