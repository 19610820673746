import React, { useState } from 'react';
import FormLabel from '../components/FormLabel';
import PrimaryButton from '../components/PrimaryButton';
import EmailInput from '../components/EmailInput';
import LinkButton from '../components/LinkButton';
import Loader from '../components/Loader';
import DangerModal from '../components/DangerModal';
import SuccessModal from '../components/SuccessModal';
import CenteredContent from '../components/CenteredContent';

import UserModel from '../models/UserModel';

const Recover = (props: any) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const recover = () : void => {
    setLoading(true);
    UserModel.recover(email).then((success: string) => {
      setEmail('');
      setSuccess(success);
      setLoading(false);
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  return (
    <CenteredContent>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <SuccessModal
              visible={success !== ""}
              text={success}
              onRequestClose={() => setSuccess('')} />
            <FormLabel text='Correo electrónico' />
            <EmailInput 
              autoFocus={true}
              placeholder='Correo eletrónico'
              editable={!loading}
              onChange={(value: string) => setEmail(value)}
              value={email} />
            <PrimaryButton 
              text='Recuperar'
              disabled={loading || !email}
              onPress={() => recover()} />
            <LinkButton 
              text="Iniciar sesión"
              disabled={loading}
              onPress={() => props.navigation.navigate('login')} />
          </>
        ) : (
          <Loader />
        )
      }
    </CenteredContent>
  );
};

export default Recover;
