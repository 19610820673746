import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import DefaultIcon from './DefaultIcon';

const LinkListItem = (props: any) => {
  return (
    <TouchableOpacity 
      style={styles.item}
      onPress={props.onPress}>
      <View>
        <Text style={styles.title}>
          {props.title}
        </Text>
        {
          props.text ? (
            <Text style={styles.text}>
              {props.text}
            </Text>
          ) : (
            null
          )
        }
      </View>
      <DefaultIcon
        icon="chevron-forward-outline"
        color="#fff"
        size={30} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    backgroundColor: 'steelblue',
    justifyContent: 'space-between',
    padding: 10,
    borderRadius: 10,
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
  },
  text: {
    color: '#fff',
  },
});

export default LinkListItem;
