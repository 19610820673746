import React, { useEffect, useState } from 'react';
import { VirtualizedList } from 'react-native';
import Loader from '../components/Loader';
import Input from '../components/Input';
import SuccessButton from '../components/SuccessButton';
import DangerModal from '../components/DangerModal';
import WarningAlert from '../components/WarningAlert';
import LinkListItem from '../components/LinkListItem';
import DefaultContent from '../components/DefaultContent';

import CustomerModel from '../models/CustomerModel';

const Customers = (props: any) => {
  const _DEFAULT_CUSTOMER = new CustomerModel(0, props.user.company_id, 
    props.user.company, '', '', '', '');

  const [customers, setCustomers] = useState<CustomerModel[]>(new Array<CustomerModel>());
  const [filter, setFilter] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  
  const Item = (item: any) : JSX.Element => {
    return (
      <LinkListItem
        title={item.item.last_name + ", " + item.item.name + " (" + item.item.email + ")"}
        text={"Número de socio: " + item.item.membership_number}
        onPress={() => props.navigation.navigate('customer', { customer: item.item }) } />
    );
  };

  const filteredCustomers = () : CustomerModel[] => {
    return customers.filter((customer: CustomerModel) : boolean => {
      return (customer.name.toLowerCase().includes(filter.toLowerCase()) || 
        customer.last_name.toLowerCase().includes(filter.toLowerCase()) || 
        customer.email.toLowerCase().includes(filter.toLowerCase()) ||
        customer.membership_number.toLowerCase().includes(filter.toLowerCase()));
    });
  };

  useEffect(() => {
    setLoading(true);
    CustomerModel.getAll(props.user.company_id, props.token).then((customers: CustomerModel[]) => {
      setCustomers(customers);
      setLoading(false);
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });

    return function cleanup() {
      setCustomers(new Array<CustomerModel>());
    };
  }, []);

  return (
    <DefaultContent>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <Input 
              autoFocus={true}
              editable={!loading}
              placeholder="Filtrar..."
              onChange={(value: string) => setFilter(value)}
              value={filter} />
            <SuccessButton
              text="Nuevo cliente"
              icon="add-outline"
              disabled={loading}
              onPress={() => props.navigation.navigate("customer", { customer: _DEFAULT_CUSTOMER })} />
            {
              filteredCustomers().length ? (
                <VirtualizedList
                  data={filteredCustomers()}
                  initialNumToRender={10}
                  renderItem={({ item }) : JSX.Element => <Item item={item} />}
                  keyExtractor={(item: CustomerModel) : string => item.id.toString()}
                  getItemCount={(data: CustomerModel[]) : number => data.length}
                  getItem={(data: CustomerModel[], index: number) : CustomerModel => data[index]} />
              ) : (
                <WarningAlert text="No existen clientes que cumplan con los filtros de búsqueda." />
              )
            }
          </>
        ) : (
          <Loader />
        )
      }
    </DefaultContent>
  );
};

export default Customers;
