import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import DangerIconButton from './DangerIconButton';

const RemovableListItem = (props: any) => {
  return (
    <View style={styles.item}>
      <View>
        <Text style={styles.title}>
          {props.title}
        </Text>
        <Text style={styles.text}>
          {props.text}
        </Text>
      </View>
      <DangerIconButton
        icon="close-outline"
        size={24}
        disabled={props.disabled}
        onPress={props.onRemovePress} />
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    backgroundColor: 'steelblue',
    justifyContent: 'space-between',
    padding: 10,
    borderRadius: 10,
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
  },
  text: {
    color: '#fff',
  },
});

export default RemovableListItem;
