import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';

const DropDown = (props: any) => {
    return (
        <View style={[styles.container, props.style]}>
            <Picker 
                style={styles.dropdown}
                enabled={props.editable}
                onValueChange={props.onChange}
                selectedValue={props.value}
                mode="dropdown"
                prompt={props.placeholder}>
                {
                    props.placeholder ? (
                        <Picker.Item 
                            style={styles.placeholder}
                            label={props.placeholder}
                            value="" />
                    ) : (
                        null
                    )
                }
                {
                    props.items.map((item: any) => {
                        return (
                            <Picker.Item 
                                key={item.value} 
                                label={item.label} 
                                value={item.value} />
                        );
                    })
                }
            </Picker>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        borderColor: '#fff',
        borderWidth: 1,
        borderStyle: 'solid',
        height: 50,
        overflow: 'hidden',
        width: '100%',
        marginBottom: 10,
        borderRadius: 10,
        backgroundColor: "#000",
        flexDirection: 'row',
        alignItems: 'center',
    },
    dropdown: {
        color: '#fff',
        backgroundColor: "#000",
        margin: 10,
        borderWidth: 0,
        width: '100%',
    },
    placeholder: {
        color: "#999",
    },
});

export default DropDown;
