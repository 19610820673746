export default class ApiService {
    private static url: string = 'http://gladiatorfitness.com.ar/api/public/index.php/api/';
    private static unexpectedError: string = 'Lo sentimos! Ha ocurrido un error inesperado. Por favor, intente nuevamente.';

    constructor() {};

    private static headers = (token: string|null) : any => {
        return token ? {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Basic ' + token,
        } : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
        };
    };

    public static get = (endpoint: string, token: string|null = null) : Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(this.url + endpoint, {
                method: 'GET',
                headers: this.headers(token),
            }).then((response: Response) => {
                if (response.status === 200) {
                    response.json().then((json: any) => {
                        resolve(json);
                    });
                }
                else if (response.status === 400) {
                    response.text().then((text: string) => {
                        reject(text);
                    });
                }
                else {
                    reject(this.unexpectedError);
                }
            }, () => {
                reject(this.unexpectedError);
            });
        });
    };

    public static post = (endpoint: string, data: any = {}, token: string|null = null) : Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(this.url + endpoint, {
                method: 'POST',
                headers: this.headers(token),
                body: JSON.stringify(data),
            }).then((response: Response) => {
                if (response.status === 200) {
                    response.json().then((json: any) => {
                        resolve(json);
                    });
                }
                else if (response.status === 400) {
                    response.text().then((text: string) => {
                        reject(text);
                    });
                }
                else {
                    reject(this.unexpectedError);
                }
            }, () => {
                reject(this.unexpectedError);
            });
        });
    };

    public static put = (endpoint: string, data: any = {}, token: string|null = null) : Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(this.url + endpoint, {
                method: 'PUT',
                headers: this.headers(token),
                body: JSON.stringify(data),
            }).then((response: Response) => {
                if (response.status === 200) {
                    response.json().then((json: any) => {
                        resolve(json);
                    });
                }
                else if (response.status === 400) {
                    response.text().then((text: string) => {
                        reject(text);
                    });
                }
                else {
                    reject(this.unexpectedError);
                }
            }, () => {
                reject(this.unexpectedError);
            });
        });
    };

    public static delete = (endpoint: string, token: string|null = null) : Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(this.url + endpoint, {
                method: 'DELETE',
                headers: this.headers(token),
            }).then((response: Response) => {
                if (response.status === 200) {
                    response.json().then((json: any) => {
                        resolve(json);
                    });
                }
                else if (response.status === 400) {
                    response.text().then((text: string) => {
                        reject(text);
                    });
                }
                else {
                    reject(this.unexpectedError);
                }
            }, () => {
                reject(this.unexpectedError);
            });
        });
    };
};
