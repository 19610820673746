export default class CompanyModel {
    id: number;
    name: string;
    logo: string;

    constructor(id: number, name: string, logo: string) {
        this.id = id;
        this.name = name;
        this.logo = logo;
    };
};
