import React, { useEffect, useState } from 'react';
import { StyleSheet, View, TextInput, Modal } from 'react-native';
import DangerButton from './DangerButton';
import PrimaryIconButton from './PrimaryIconButton';
import { Calendar } from 'react-native-calendars';
import { DateData, Direction } from 'react-native-calendars/src/types';
import DefaultIcon from './DefaultIcon';

import Moment from 'moment';
Moment.locale('es');

import { LocaleConfig } from 'react-native-calendars';
LocaleConfig.locales['es'] = {
  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 
    'Octubre', 'Noviembre', 'Diciembre'],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul.', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  today: 'Hoy'
};
LocaleConfig.defaultLocale = 'es';

const DateInput = (props: any) => {
    const [date, setDate] = useState<Date>(props.value || null);
    const [open, setOpen] = useState<boolean>(false);

    const confirm = (value: Date) : void => {
        setDate(value);
        setOpen(false);
    };

    const cancel = () : void => {
        setOpen(false);
    };

    useEffect(() => {
        props.onChange(date);
    }, [date]);

    return (
        <View style={styles.container}>
            <Modal
                animationType="fade"
                transparent={true}
                visible={open}
                onRequestClose={() => cancel()}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Calendar
                            // Initially visible month. Default = now
                            current={props.value}
                            // Handler which gets executed on day press. Default = undefined
                            onDayPress={(value: DateData) => confirm(new Date(value.year, value.month - 1, value.day))}
                            // Month format in calendar title. Formatting values: http://arshaw.com/xdate/#Formatting
                            monthFormat={'MMMM yyyy'}
                            // Hide month navigation arrows. Default = false
                            hideArrows={false}
                            // Do not show days of other months in month page. Default = false
                            hideExtraDays={false}
                            // If hideArrows = false and hideExtraDays = false do not switch month when tapping on greyed out
                            // day from another month that is visible in calendar page. Default = false
                            disableMonthChange={false}
                            // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday
                            firstDay={0}
                            // Hide day names. Default = false
                            hideDayNames={false}
                            // Show week numbers to the left. Default = false
                            showWeekNumbers={false}
                            // Disable left arrow. Default = false
                            disableArrowLeft={false}
                            // Disable right arrow. Default = false
                            disableArrowRight={false}
                            // Disable all touch events for disabled days. can be override with disableTouchEvent in markedDates
                            disableAllTouchEventsForDisabledDays={false}
                            // Enable the option to swipe between months. Default = false
                            enableSwipeMonths={false}
                            // Replace default arrows with custom ones (direction can be 'left' or 'right')
                            renderArrow={(direction: Direction) => {
                                if (direction === 'left') {
                                    return (
                                        <DefaultIcon 
                                            icon="chevron-back-outline" 
                                            size={20}
                                            color="steelblue" />
                                    );
                                }
                                else if (direction === 'right') {
                                    return (
                                        <DefaultIcon 
                                            icon="chevron-forward-outline" 
                                            size={20}
                                            color="steelblue" />
                                    );
                                }
                                else {
                                    return (null);
                                }
                            }}
                            markedDates={{
                                [Moment(date).format("YYYY-MM-DD")]: {
                                  selected: true,
                                  disableTouchEvent: false,
                                },
                            }}
                            theme={{
                                selectedDayBackgroundColor: 'steelblue',
                                todayTextColor: 'steelblue',
                            }} />
                        <DangerButton
                            text="Cancelar"
                            disabled={false}
                            onPress={() => cancel()} />
                    </View>
                </View>
            </Modal>
            <TextInput 
                style={styles.input}
                autoCapitalize='none'
                autoFocus={false}
                editable={false}
                keyboardType='default'
                maxLength={10}
                multiline={false}
                placeholder={props.placeholder}
                placeholderTextColor='#999'
                secureTextEntry={false}
                textAlign='left'
                textContentType='none'
                value={Moment(date).format("DD-MM-YYYY")} />
            <PrimaryIconButton
                style={styles.button}
                disabled={!props.editable}
                onPress={() => setOpen(true)}
                icon="calendar-outline"
                size={16} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        borderColor: '#fff',
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 10,
        marginBottom: 10,
        width: '100%',
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 50,
    },
    input: {
        color: '#fff',
    },
    button: {
        margin: -5,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    modalView: {
        margin: 10,
        backgroundColor: "white",
        borderRadius: 10,
        padding: 10,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 5,
        elevation: 5,
        minWidth: 300,
    },
});

export default DateInput;
