import React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';

const Loader = (props: any) => {
  return (
    <View style={[styles.container, styles.horizontal]}>
        <ActivityIndicator 
            size="large" 
            color="steelblue" />
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center"
    },
    horizontal: {
      flexDirection: "row",
      justifyContent: "space-around",
      padding: 10
    }
});

export default Loader;
