import React from 'react';
import PrimaryIconButton from '../components/PrimaryIconButton';
import DangerIconButton from '../components/DangerIconButton';
import Content from '../components/Content';

const Main = (props: any) => {
  return (
    <Content>
      <PrimaryIconButton
        style={{
          flexBasis: 300,
        }}
        icon="person-outline"
        size={100}
        text="Usuarios"
        onPress={() => props.navigation.navigate('users')} />
      <PrimaryIconButton
        style={{
          flexBasis: 300,
        }}
        icon="briefcase-outline"
        size={100}
        text="Clientes"
        onPress={() => props.navigation.navigate('customers')} />
      <PrimaryIconButton
        style={{
          flexBasis: 300,
        }}
        icon="barbell-outline"
        size={100}
        text="Ejercicios"
        onPress={() => props.navigation.navigate('exercises')} />
      <PrimaryIconButton
        style={{
          flexBasis: 300,
        }}
        icon="calendar-outline"
        size={100}
        text="Rutinas"
        onPress={() => props.navigation.navigate('routines')} />
      <PrimaryIconButton
        style={{
          flexBasis: 300,
        }}
        icon="lock-closed-outline"
        size={100}
        text="Cambiar contraseña"
        onPress={() => props.navigation.navigate('change-password')} />
      <DangerIconButton
        style={{
          flexBasis: 300,
        }}
        icon="exit-outline"
        size={100}
        text="Salir"
        onPress={() => props.onLogout()} />
    </Content>
  );
};

export default Main;
