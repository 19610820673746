import React, { useState } from 'react';
import FormLabel from '../components/FormLabel';
import PrimaryButton from '../components/PrimaryButton';
import PasswordInput from '../components/PasswordInput';
import Loader from '../components/Loader';
import DangerModal from '../components/DangerModal';
import CenteredContent from '../components/CenteredContent';
import { Buffer } from 'buffer';

import UserModel from '../models/UserModel';
import SuccessModal from '../components/SuccessModal';

const ChangePassword = (props: any) => {
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const changePassword = () : void => {
    setLoading(true);
    UserModel.reset(props.user.company_id, props.user.id, password, password2).then((success: string) => {
      setPassword('');
      setPassword2('');
      setSuccess(success);
      setLoading(false);
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  const onSuccessRequestClose = () : void => {
    setSuccess('');
    props.onSuccess(Buffer.from(props.user.email + ":" + password).toString('base64'));
  };

  return (
    <CenteredContent>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <SuccessModal
              visible={success !== ""}
              text={success}
              onRequestClose={() => onSuccessRequestClose()} />
            <FormLabel text='Nueva contraseña' />
            <PasswordInput 
              autoFocus={true}
              placeholder='Nueva contraseña'
              editable={!loading}
              onChange={(value: string) => setPassword(value)}
              value={password} />
            <FormLabel text='Repetir nueva contraseña' />
            <PasswordInput
              autoFocus={false}
              placeholder='Repetir nueva contraseña'
              editable={!loading}
              onChange={(value: string) => setPassword2(value)}
              value={password2} />
            <PrimaryButton 
              text='Cambiar'
              disabled={loading || !password || !password2}
              onPress={() => changePassword()} />
          </>
        ) : (
          <Loader />
        )
      }
    </CenteredContent>
  );
};

export default ChangePassword;
