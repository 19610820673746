import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import DefaultIcon from './DefaultIcon';

const WarningAlert = (props: any) => {
  return (
    <View 
      style={styles.alert}>
      <DefaultIcon 
        icon="warning-outline" 
        size={30}
        color="#664d03" />
      <Text>
        {props.text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  alert: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFF3CD',
    color: '#A56404',
    width: '100%',
    maxWidth: '100%',
    borderRadius: 10,
    padding: 10,
    minHeight: 50,
  },
});

export default WarningAlert;
