import React, { useEffect, useState } from 'react';
import { VirtualizedList } from 'react-native';
import Loader from '../components/Loader';
import Input from '../components/Input';
import SuccessButton from '../components/SuccessButton';
import DangerModal from '../components/DangerModal';
import DateInput from '../components/DateInput';
import WarningAlert from '../components/WarningAlert';
import LinkListItem from '../components/LinkListItem';
import DefaultContent from '../components/DefaultContent';

import RoutineModel from '../models/RoutineModel';
import CustomerModel from '../models/CustomerModel';
import RoutineExerciseModel from '../models/RoutineExerciseModel';

import Moment from 'moment';
Moment.locale('es');

const Routines = (props: any) => {
  const _DEFAULT_ROUTINE = new RoutineModel(0, props.user.company_id, props.user.company, 
    Moment(new Date()).format("YYYY-MM-DD"), null, new CustomerModel(0, props.user.company_id, 
    props.user.company, '', '', '', ''), '', new Array<RoutineExerciseModel>());

  const [routines, setRoutines] = useState<RoutineModel[]>(new Array<RoutineModel>());
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const [filter, setFilter] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  
  const Item = (item: any) : JSX.Element => {
    return (
      <LinkListItem
        title={Moment(item.item.date).format("DD-MM-YYYY")}
        text={item.item.customer?.last_name + ", " + item.item.customer?.name}
        onPress={() => props.navigation.navigate('routine', { routine: item.item }) } />
    );
  };

  const filteredRoutines = () : RoutineModel[] => {
    return routines.filter((routine: RoutineModel) : boolean => {
      return (routine.customer?.name.toLowerCase().includes(filter.toLowerCase()) || 
        routine.customer?.last_name.toLowerCase().includes(filter.toLowerCase()) ||
        routine.description.toLowerCase().includes(filter.toLowerCase()));
    });
  };

  useEffect(() => {
    setLoading(true);
    RoutineModel.getAll(props.user.company_id, props.token, dateFilter).then((routines: RoutineModel[]) => {
      setRoutines(routines);
      setLoading(false);
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });

    return function cleanup() {
      setRoutines(new Array<RoutineModel>());
    };
  }, [dateFilter]);

  return (
    <DefaultContent>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <DateInput
              onChange={(value: Date) => setDateFilter(value)}
              editable={!loading}
              value={dateFilter}
              autoFocus={false}
              placeholder="Fecha" />
            <Input 
              autoFocus={false}
              editable={!loading}
              placeholder="Filtrar..."
              onChange={(value: string) => setFilter(value)}
              value={filter} />
            <SuccessButton
              text="Nueva rutina"
              icon="add-outline"
              disabled={loading}
              onPress={() => props.navigation.navigate("routine", { routine: _DEFAULT_ROUTINE })} />
            {
              filteredRoutines().length ? (
                <VirtualizedList
                  data={filteredRoutines()}
                  initialNumToRender={10}
                  renderItem={({ item }) : JSX.Element => <Item item={item} />}
                  keyExtractor={(item: RoutineModel) : string => item.id.toString()}
                  getItemCount={(data: RoutineModel[]) : number => data.length}
                  getItem={(data: RoutineModel[], index: number) : RoutineModel => data[index]} />
              ) : (
                <WarningAlert text="No existen rutinas que cumplan con los filtros de búsqueda." />
              )
            }
          </>
        ) : (
          <Loader />
        )
      }
    </DefaultContent>
  );
};

export default Routines;
