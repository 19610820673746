import { disableExpoCliLogging } from 'expo/build/logs/Logs';
import React from 'react';
import { StyleSheet, Text, Modal, View } from 'react-native';
import DangerButton from './DangerButton';
import DefaultIcon from './DefaultIcon';

const DangerModal = (props: any) => {
  return (
    <Modal
        animationType="fade"
        transparent={true}
        visible={props.visible}
        onRequestClose={props.onRequestClose}>
        <View style={styles.centeredView}>
            <View style={styles.modalView}>
                <Text style={styles.modalText}>
                    <DefaultIcon style={styles.modalIcon}
                        icon="warning-outline"
                        color="#f33" 
                        size={30} />
                    {props.text}
                </Text>
                <DangerButton
                    text="OK"
                    disabled={false}
                    onPress={props.onRequestClose} />
            </View>
        </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    modalView: {
        margin: 10,
        backgroundColor: "#121212",
        borderRadius: 10,
        padding: 10,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 5,
        elevation: 5,
        width: 300,
    },
    modalText: {
        marginBottom: 15,
        textAlign: "left",
        color: "#fff",
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        display: 'flex',
    },
    modalIcon: {
        marginRight: 10,
    },
});

export default DangerModal;
