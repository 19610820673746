import React, { useEffect, useState } from 'react';
import { StyleSheet, TextInput } from 'react-native';

const PasswordInput = (props: any) => {
  const [value, setValue] = useState<string>(props.value);

  useEffect(() => {
      props.onChange(value);
  }, [value]);
  
  return (
    <TextInput 
      style={styles.input}
      autoCapitalize='none'
      autoFocus={props.autoFocus}
      editable={props.editable}
      keyboardType='default'
      multiline={false}
      onBlur={props.onBlur}
      onChangeText={(value: string) => setValue(value)}
      placeholder={props.placeholder}
      placeholderTextColor='#999'
      secureTextEntry={true}
      textAlign='left'
      textContentType='password'
      value={props.value} />
  );
}

const styles = StyleSheet.create({
  input: {
    borderColor: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff',
    padding: 10,
    marginBottom: 10,
    width: '100%',
    borderRadius: 10,
    height: 50,
  },
});

export default PasswordInput;
