import ApiService from "../services/ApiService";
import CompanyModel from "./CompanyModel";

export default class UserModel {
    id: number;
    company_id: number;
    company: CompanyModel;
    name: string;
    last_name: string;
    email: string;
    profile: string;

    constructor(id: number, company_id: number, company: CompanyModel, name: string, 
        last_name: string, email: string, profile: string) {
        this.id = id;
        this.company_id = company_id;
        this.company = company;
        this.name = name;
        this.last_name = last_name;
        this.email = email;
        this.profile = profile;
    };

    public static getAll = (company_id: number, token: string) : Promise<UserModel[]> => {
        return new Promise((resolve, reject) => {
            ApiService.get('user/' + company_id.toString(), token).then((response: any) => {
                resolve(response.map((response: any) => new UserModel(response.id, response.company_id, 
                    new CompanyModel(response.company.id, response.company.name, response.company.logo), 
                    response.name, response.last_name, response.email, response.profile)));
            }, (error: string) => {
                reject(error);
            });
        });
    };

    public static get = (company_id: number, user_id: number) : Promise<UserModel> => {
        return new Promise((resolve, reject) => {
            ApiService.get('user/' + company_id.toString() + "/" + user_id.toString()).then((response: any) => {
                resolve(new UserModel(response.id, response.company_id, new CompanyModel(response.company.id, 
                    response.company.name, response.company.logo), response.name, response.last_name, 
                    response.email, response.profile));
            }, (error: string) => {
                reject(error);
            });
        });
    };

    public static save = (company_id: number, token: string, user: UserModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (user.id === 0) {
                ApiService.post('user/' + company_id.toString(), user, token).then(() => {
                    resolve();
                }, (error: string) => {
                    reject(error);
                });
            }
            else {
                ApiService.put('user/' + company_id.toString() + "/" + user.id.toString(), user, token).then(() => {
                    resolve();
                }, (error: string) => {
                    reject(error);
                });
            }
        });
    };

    public static delete = (company_id: number, token: string, user_id: number) : Promise<void> => {
        return new Promise((resolve, reject) => {
            ApiService.delete('user/' + company_id.toString() + "/" + user_id.toString(), token).then(() => {
                resolve();
            }, (error: string) => {
                reject(error);
            });
        });
    };

    public static login = (email: string, password: string) : Promise<UserModel> => {
        return new Promise((resolve, reject) => {
            ApiService.post('auth/login', {
                email       : email,
                password    : password,
            }).then((response: any) => {
                resolve(new UserModel(response.user.id, response.user.company_id, 
                    new CompanyModel(response.user.company.id, response.user.company.name, 
                    response.user.company.logo), response.user.name, response.user.last_name, 
                    response.user.email, response.user.profile));
            }, (error: string) => {
                reject(error);
            });
        });
    };

    public static recover = (email: string) : Promise<string> => {
        return new Promise((resolve, reject) => {
            ApiService.post('auth/recover', {
                email   : email,
            }).then((response: any) => {
                resolve(response.message);
            }, (error: string) => {
                reject(error);
            });
        });
    };

    public static reset = (company_id: number, user_id: number, password: string, password2: string) : Promise<string> => {
        return new Promise((resolve, reject) => {
            ApiService.post('auth/reset/' + company_id.toString() + "/" + user_id.toString(), {
                password    : password,
                password2   : password2,
            }).then((response: any) => {
                resolve(response.message);
            }, (error: string) => {
                reject(error);
            });
        });
    };
};
