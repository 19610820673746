import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import DefaultIcon from './DefaultIcon';

const PrimaryButton = (props: any) => {
  return (
    <TouchableOpacity 
        style={styles.primaryButton}
        disabled={props.disabled}
        onPress={props.onPress}>
        <Text style={styles.primaryButtonText}>
            {
                props.icon ? (
                    <DefaultIcon 
                        icon={props.icon} 
                        color="#fff" 
                        size={18} />
                ) : (
                    null
                )
            }
            {props.text}
        </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
    primaryButton: {
        backgroundColor: 'steelblue',
        width: '100%',
        justifyContent: 'center',
        padding: 10,
        borderRadius: 10,
        marginVertical: 10,
        height: 50,
    },
        primaryButtonText: {
        color: '#fff',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 18,
    },
});

export default PrimaryButton;
