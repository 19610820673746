import React from 'react';
import { StyleSheet, ScrollView } from 'react-native';

const CenteredContent = (props: any) => {
  return (
    <ScrollView contentContainerStyle={styles.content}>
      {props.children}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: 300,
  },
});

export default CenteredContent;
