import React, { useState } from 'react';
import DangerButton from '../components/DangerButton';
import Input from '../components/Input';
import EmailInput from '../components/EmailInput';
import Loader from '../components/Loader';
import SecondaryButton from '../components/SecondaryButton';
import SuccessButton from '../components/SuccessButton';
import DangerModal from '../components/DangerModal';
import Content from '../components/Content';

import CustomerModel from '../models/CustomerModel';

const Customer = (props: any) => {
  const [customer, setCustomer] = useState<CustomerModel>(Object.assign({}, props.route.params.customer));
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onSave = () : void => {
    setLoading(true);
    CustomerModel.save(props.user.company_id, props.token, customer).then(() => {
      props.navigation.navigate("main");
      props.navigation.navigate("customers");
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  const onDelete = () : void => {
    setLoading(true);
    CustomerModel.delete(props.user.company_id, props.token, customer.id).then(() => {
      props.navigation.navigate("main");
      props.navigation.navigate("customers");
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  return (
    <Content>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <Input
              autoFocus={true}
              editable={!loading}
              placeholder="Nombre"
              maxLength={50}
              value={customer.name}
              onChange={(value: string) => setCustomer({...customer, name: value})} />
            <Input
              autoFocus={false}
              editable={!loading}
              placeholder="Apellido"
              maxLength={50}
              value={customer.last_name}
              onChange={(value: string) => setCustomer({...customer, last_name: value})} />
            <EmailInput
              autoFocus={false}
              editable={!loading}
              placeholder="Correo electrónico"
              value={customer.email}
              onChange={(value: string) => setCustomer({...customer, email: value})} />
            <Input
              autoFocus={false}
              editable={!loading}
              placeholder="Número de socio"
              maxLength={50}
              value={customer.membership_number}
              onChange={(value: string) => setCustomer({...customer, membership_number: value})} />
            <SecondaryButton
              text="Volver"
              icon="chevron-back-outline"
              disabled={loading}
              onPress={() => props.navigation.navigate("customers")} />
            <SuccessButton
              text="Guardar cambios"
              icon="checkmark-outline"
              disabled={loading || !customer.name || !customer.last_name || !customer.email}
              onPress={() => onSave()} />
          {
            (customer.id !== 0) ? (
              <DangerButton
                text="Eliminar"
                icon="close-outline"
                disabled={loading}
                onPress={() => onDelete()} />
            ) : (
              null
            )
          }
          </>
        ) : (
          <Loader />
        )
      }
    </Content>
  );
};

export default Customer;
