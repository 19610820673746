import React, { useState } from 'react';
import DangerButton from '../components/DangerButton';
import Input from '../components/Input';
import EmailInput from '../components/EmailInput';
import Loader from '../components/Loader';
import SecondaryButton from '../components/SecondaryButton';
import SuccessButton from '../components/SuccessButton';
import DangerModal from '../components/DangerModal';
import Content from '../components/Content';

import UserModel from '../models/UserModel';

const User = (props: any) => {
  const [user, setUser] = useState<UserModel>(Object.assign({}, props.route.params.user));
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onSave = () : void => {
    setLoading(true);
    UserModel.save(props.user.company_id, props.token, user).then(() => {
      props.navigation.navigate("main");
      props.navigation.navigate("users");
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  const onDelete = () : void => {
    setLoading(true);
    UserModel.delete(props.user.company_id, props.token, user.id).then(() => {
      props.navigation.navigate("main");
      props.navigation.navigate("users");
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  return (
    <Content>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <Input
              autoFocus={true}
              editable={!loading}
              placeholder="Nombre"
              maxLength={50}
              value={user.name}
              onChange={(value: string) => setUser({ ...user, name: value })} />
            <Input
              autoFocus={false}
              editable={!loading}
              placeholder="Apellido"
              maxLength={50}
              value={user.last_name}
              onChange={(value: string) => setUser({ ...user, last_name: value })} />
            <EmailInput
              autoFocus={false}
              editable={!loading}
              placeholder="Correo electrónico"
              value={user.email}
              onChange={(value: string) => setUser({ ...user, email: value })} />
            <SecondaryButton
              text="Volver"
              icon="chevron-back-outline"
              disabled={loading}
              onPress={() => props.navigation.navigate("users")} />
            <SuccessButton
              text="Guardar cambios"
              icon="checkmark-outline"
              disabled={loading || !user.name || !user.last_name || !user.email}
              onPress={() => onSave()} />
            {
              (user.id !== 0 && user.id !== props.user.id) ? (
                <DangerButton
                  text="Eliminar"
                  icon="close-outline"
                  disabled={loading}
                  onPress={() => onDelete()} />
              ) : (
                null
              )
            }
          </>
        ) : (
          <Loader />
        )
      }
    </Content>
  );
};

export default User;
