import ApiService from "../services/ApiService";
import CompanyModel from "./CompanyModel";
import CustomerModel from "./CustomerModel";
import ExerciseModel from "./ExerciseModel";
import RoutineExerciseModel from "./RoutineExerciseModel";

import Moment from 'moment';
Moment.locale('es');

export default class RoutineModel {
    id: number;
    company_id: number;
    company: CompanyModel;
    date: string;
    customer_id: number|null;
    customer: CustomerModel|null;
    description: string;
    routine_exercises: RoutineExerciseModel[];

    constructor(id: number, company_id: number, company: CompanyModel, date: string, 
        customer_id: number|null, customer: CustomerModel|null, description: string, 
        routine_exercises: RoutineExerciseModel[]) {
        this.id = id;
        this.company_id = company_id;
        this.company = company;
        this.date = date;
        this.customer_id = customer_id;
        this.customer = customer;
        this.description = description;
        this.routine_exercises = routine_exercises;
    };

    public static getAll = (company_id: number, token: string, date: Date) : Promise<RoutineModel[]> => {
        return new Promise((resolve, reject) => {
            ApiService.get('routine/' + company_id.toString() + "/date/" + Moment(date).format("YYYY-MM-DD"), token).then((response: any) => {
                resolve(response.map((response: any) => new RoutineModel(response.id, response.company_id, 
                    new CompanyModel(response.company.id, response.company.name, response.company.logo), 
                    response.date, response.customer_id, new CustomerModel(response.customer.id, 
                    response.customer.company_id, new CompanyModel(response.customer.company.id, 
                    response.customer.company.name, response.customer.company.logo), 
                    response.customer.user.name, response.customer.user.last_name, response.customer.user.email, 
                    response.customer.membership_number), response.description, 
                    response.routine_exercises.map((re: any) => new RoutineExerciseModel(re.id, re.routine_id,
                    re.exercise_id, new ExerciseModel(re.exercise.id, re.exercise.company_id, 
                    new CompanyModel(re.exercise.company.id, re.exercise.company.name, re.exercise.company.logo), 
                    re.exercise.name, re.exercise.description, re.exercise.video), re.series, re.repetitions, 
                    re.description)))));
            }, (error: string) => {
                reject(error);
            });
        });
    };

    public static getByUser = (company_id: number, user_id: number, token: string, date: Date) : Promise<RoutineModel|null> => {
        return new Promise((resolve, reject) => {
            ApiService.get('routine/' + company_id.toString() + "/" + user_id.toString() + "/date/" + Moment(date).format("YYYY-MM-DD"), token).then((response: any) => {
                if (response.id) {
                    resolve(new RoutineModel(response.id, response.company_id, 
                        new CompanyModel(response.company.id, response.company.name, response.company.logo), 
                        response.date, response.customer_id, new CustomerModel(response.customer.id, 
                        response.customer.company_id, new CompanyModel(response.customer.company.id, 
                        response.customer.company.name, response.customer.company.logo), response.customer.user.name, 
                        response.customer.user.last_name, response.customer.user.email, 
                        response.customer.membership_number), response.description, 
                        response.routine_exercises.map((re: any) => new RoutineExerciseModel(re.id, re.routine_id,
                        re.exercise_id, new ExerciseModel(re.exercise.id, re.exercise.company_id, 
                        new CompanyModel(re.exercise.company.id, re.exercise.company.name, re.exercise.company.logo), 
                        re.exercise.name, re.exercise.description, re.exercise.video), re.series, re.repetitions, 
                        re.description))));
                }
                else {
                    resolve(null);
                }
            }, (error: string) => {
                reject(error);
            });
        });
    };

    public static save = (company_id: number, token: string, routine: RoutineModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (routine.id === 0) {
                ApiService.post('routine/' + company_id.toString(), routine, token).then(() => {
                    resolve();
                }, (error: string) => {
                    reject(error);
                });
            }
            else {
                ApiService.put('routine/' + company_id.toString() + "/" + routine.id.toString(), routine, token).then(() => {
                    resolve();
                }, (error: string) => {
                    reject(error);
                });
            }
        });
    };

    public static delete = (company_id: number, token: string, routine_id: number) : Promise<void> => {
        return new Promise((resolve, reject) => {
            ApiService.delete('routine/' + company_id.toString() + "/" + routine_id.toString(), token).then(() => {
                resolve();
            }, (error: string) => {
                reject(error);
            });
        });
    };
};
