import ExerciseModel from "./ExerciseModel";

export default class RoutineExerciseModel {
    id: number;
    routine_id: number;
    exercise_id: number|null;
    exercise: ExerciseModel|null;
    series: number|null;
    repetitions: number|null;
    description: string;

    constructor(id: number, routine_id: number, exercise_id: number|null, exercise: ExerciseModel|null, 
        series: number|null, repetitions: number|null, description: string) {
        this.id = id;
        this.routine_id = routine_id;
        this.exercise_id = exercise_id;
        this.exercise = exercise;
        this.series = series;
        this.repetitions = repetitions;
        this.description = description;
    };
};
