import React, { useEffect, useState } from 'react';
import { ListRenderItemInfo, VirtualizedList, Text } from 'react-native';
import Loader from '../components/Loader';
import Input from '../components/Input';
import SuccessButton from '../components/SuccessButton';
import DangerModal from '../components/DangerModal';
import WarningAlert from '../components/WarningAlert';
import LinkListItem from '../components/LinkListItem';
import DefaultContent from '../components/DefaultContent';

import UserModel from '../models/UserModel';

const Users = (props: any) => {
  const _DEFAULT_USER = new UserModel(0, props.user.company_id, props.user.company, '', '', '', 'A');

  const [users, setUsers] = useState<UserModel[]>(new Array<UserModel>());
  const [filter, setFilter] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  
  const Item = (item: any) : JSX.Element => {
    return (
      <LinkListItem
        title={item.item.last_name + ", " + item.item.name}
        text={item.item.email}
        onPress={() => props.navigation.navigate('user', { user: item.item }) } />
    );
  };

  const filteredUsers = () : UserModel[] => {
    return users.filter((user: UserModel) : boolean => {
      return (user.name.toLowerCase().includes(filter.toLowerCase()) || 
        user.last_name.toLowerCase().includes(filter.toLowerCase()) || 
        user.email.toLowerCase().includes(filter.toLowerCase()));
    });
  };

  useEffect(() => {
    setLoading(true);
    UserModel.getAll(props.user.company_id, props.token).then((users: UserModel[]) => {
      setUsers(users);
      setLoading(false);
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });

    return function cleanup() {
      setUsers(new Array<UserModel>());
    };
  }, []);

  return (
    <DefaultContent>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <Input 
              autoFocus={true}
              editable={!loading}
              placeholder="Filtrar..."
              onChange={(value: string) => setFilter(value)}
              value={filter} />
            <SuccessButton
              text="Nuevo usuario"
              icon="add-outline"
              disabled={loading}
              onPress={() => props.navigation.navigate("user", { user: _DEFAULT_USER })} />
            {
              filteredUsers().length ? (
                <VirtualizedList
                  data={filteredUsers()}
                  initialNumToRender={10}
                  renderItem={(item: any) : JSX.Element => <Item item={item.item} />}
                  keyExtractor={(item: UserModel) : string => item.id.toString()}
                  getItemCount={(data: UserModel[]) : number => data.length}
                  getItem={(data: UserModel[], index: number) : UserModel => data[index]} />
              ) : (
                <WarningAlert text="No existen usuarios que cumplan con los filtros de búsqueda." />
              )
            }
          </>
        ) : (
          <Loader />
        )
      }
    </DefaultContent>
  );
};

export default Users;
