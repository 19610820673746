import React, { useState } from 'react';
import DangerButton from '../components/DangerButton';
import Input from '../components/Input';
import Loader from '../components/Loader';
import SecondaryButton from '../components/SecondaryButton';
import SuccessButton from '../components/SuccessButton';
import DangerModal from '../components/DangerModal';
import UrlInput from '../components/UrlInput';
import MultilineInput from '../components/MultilineInput';
import Content from '../components/Content';

import ExerciseModel from '../models/ExerciseModel';

const Exercise = (props: any) => {
  const [exercise, setExercise] = useState<ExerciseModel>(Object.assign({}, props.route.params.exercise));
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onSave = () : void => {
    setLoading(true);
    ExerciseModel.save(props.user.company_id, props.token, exercise).then(() => {
      props.navigation.navigate("main");
      props.navigation.navigate("exercises");
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  const onDelete = () : void => {
    setLoading(true);
    ExerciseModel.delete(props.user.company_id, props.token, exercise.id).then(() => {
      props.navigation.navigate("main");
      props.navigation.navigate("exercises");
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  return (
    <Content>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <Input
              autoFocus={true}
              editable={!loading}
              placeholder="Nombre"
              maxLength={50}
              value={exercise.name}
              onChange={(value: string) => setExercise({...exercise, name: value})} />
            <UrlInput
              autoFocus={false}
              editable={!loading}
              placeholder="URL del Video"
              maxLength={255}
              value={exercise.video}
              onChange={(value: string) => setExercise({...exercise, video: value})} />
            <MultilineInput
              autoFocus={false}
              editable={!loading}
              placeholder="Descripción"
              value={exercise.description}
              onChange={(value: string) => setExercise({...exercise, description: value})} />
            <SecondaryButton
              text="Volver"
              icon="chevron-back-outline"
              disabled={loading}
              onPress={() => props.navigation.navigate("exercises")} />
            <SuccessButton
              text="Guardar cambios"
              icon="checkmark-outline"
              disabled={loading || !exercise.name || !exercise.video}
              onPress={() => onSave()} />
            {
              (exercise.id !== 0) ? (
                <DangerButton
                  text="Eliminar"
                  icon="close-outline"
                  disabled={loading}
                  onPress={() => onDelete()} />
              ) : (
                null
              )
            }
          </>
        ) : (
          <Loader />
        )
      }
    </Content>
  );
};

export default Exercise;
