import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

const Label = (props: any) => {
    return (
        <View style={styles.container}>
            <Text style={styles.label}>
                {props.text}
            </Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginBottom: 10,
        width: '100%',
        textAlign: 'left',
    },
    label: {
        color: '#fff',
    },
});

export default Label;
