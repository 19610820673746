import React, { useState } from 'react';
import FormLabel from '../components/FormLabel';
import PrimaryButton from '../components/PrimaryButton';
import EmailInput from '../components/EmailInput';
import PasswordInput from '../components/PasswordInput';
import LinkButton from '../components/LinkButton';
import Loader from '../components/Loader';
import DangerModal from '../components/DangerModal';
import CenteredContent from '../components/CenteredContent';
import { Buffer } from 'buffer';

import UserModel from '../models/UserModel';

const Login = (props: any) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const login = () : void => {
    setLoading(true);
    UserModel.login(email, password).then((user: UserModel) => {
      props.onSuccess(user, Buffer.from(email + ":" + password).toString('base64'));
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  return (
    <CenteredContent>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <FormLabel text='Correo electrónico' />
            <EmailInput 
              autoFocus={true}
              placeholder='Correo eletrónico'
              editable={!loading}
              onChange={(value: string) => setEmail(value)}
              value={email} />
            <FormLabel text='Contraseña' />
            <PasswordInput
              placeholder='Contraseña'
              editable={!loading}
              onChange={(value: string) => setPassword(value)}
              value={password} />
            <PrimaryButton 
              text='Ingresar'
              disabled={loading || !email || !password}
              onPress={() => login()} />
            <LinkButton 
              text="Olvidé mi contraseña"
              disabled={loading}
              onPress={() => props.navigation.navigate('recover')} />
          </>
        ) : (
          <Loader />
        )
      }
    </CenteredContent>
  );
};

export default Login;
