import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

const LinkButton = (props: any) => {
  return (
    <TouchableOpacity 
        style={styles.primaryButton}
        disabled={props.disabled}
        onPress={props.onPress}>
        <Text style={styles.primaryButtonText}>
            {props.text}
        </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
    primaryButton: {
        marginTop: 20,
    },
    primaryButtonText: {
        color: 'steelblue',
        textAlign: 'center',
        fontSize: 14,
    },
});

export default LinkButton;
