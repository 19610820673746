import React from 'react';
import { StyleSheet, Image } from 'react-native';

const Logo = (props: any) => {
  return props.source ? (
    <Image 
      style={styles.logo}
      source={{ uri: props.source }} />
  ) : (
    null
  );
};

const styles = StyleSheet.create({
  logo: {
    height: 50,
    width: 50,
  },
});

export default Logo;
