import React from 'react';
import { StyleSheet, Text } from 'react-native';

const FormLabel = (props: any) => {
  return (
    <Text style={styles.formLabel}>
        {props.text}
    </Text>
  );
}

const styles = StyleSheet.create({
  formLabel: {
    color: '#fff',
    textAlign: 'left',
    marginBottom: 5,
  },
});

export default FormLabel;
