import React, { useEffect, useState } from 'react';
import { VirtualizedList } from 'react-native';
import Loader from '../components/Loader';
import Input from '../components/Input';
import SuccessButton from '../components/SuccessButton';
import DangerModal from '../components/DangerModal';
import WarningAlert from '../components/WarningAlert';
import LinkListItem from '../components/LinkListItem';
import DefaultContent from '../components/DefaultContent';

import ExerciseModel from '../models/ExerciseModel';

const Exercises = (props: any) => {
  const _DEFAULT_EXERCISE = new ExerciseModel(0, props.user.company_id, 
    props.user.company, '', '', '');

  const [exercises, setExercises] = useState<ExerciseModel[]>(new Array<ExerciseModel>());
  const [filter, setFilter] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  
  const Item = (item: any) : JSX.Element => {
    return (
      <LinkListItem
        title={item.item.name}
        text=""
        onPress={() => props.navigation.navigate('exercise', { exercise: item.item })} />
    );
  };

  const filteredExercises = () : ExerciseModel[] => {
    return exercises.filter((exercise: ExerciseModel) : boolean => {
      return (exercise.name.toLowerCase().includes(filter.toLowerCase()) || 
        exercise.description.toLowerCase().includes(filter.toLowerCase()));
    });
  };

  useEffect(() => {
    setLoading(true);
    ExerciseModel.getAll(props.user.company_id, props.token).then((exercises: ExerciseModel[]) => {
      setExercises(exercises);
      setLoading(false);
    }, (error: string) => {
      setError(error);
      setLoading(false);
    });

    return function cleanup() {
      setExercises(new Array<ExerciseModel>());
    };
  }, []);

  return (
    <DefaultContent>
      {
        !loading ? (
          <>
            <DangerModal
              visible={error !== ""}
              text={error}
              onRequestClose={() => setError('')} />
            <Input 
              autoFocus={true}
              editable={!loading}
              placeholder="Filtrar..."
              onChange={(value: string) => setFilter(value)}
              value={filter} />
            <SuccessButton
              text="Nuevo ejercicio"
              icon="add-outline"
              disabled={loading}
              onPress={() => props.navigation.navigate("exercise", { exercise: _DEFAULT_EXERCISE })} />
            {
              filteredExercises().length ? (
                <VirtualizedList
                  data={filteredExercises()}
                  initialNumToRender={10}
                  renderItem={({ item }) : JSX.Element => <Item item={item} />}
                  keyExtractor={(item: ExerciseModel) : string => item.id.toString()}
                  getItemCount={(data: ExerciseModel[]) : number => data.length}
                  getItem={(data: ExerciseModel[], index: number) : ExerciseModel => data[index]} />
              ) : (
                <WarningAlert text="No existen ejercicios que cumplan con los filtros de búsqueda." />
              )
            }
          </>
        ) : (
          <Loader />
        )
      }
    </DefaultContent>
  );
};

export default Exercises;
