import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from 'react-native';
import DefaultIcon from './DefaultIcon';

const DangerIconButton = (props: any) => {
  return (
    <TouchableOpacity 
      style={[styles.iconButton, props.style]}
      disabled={props.disabled}
      onPress={props.onPress}>
      <DefaultIcon 
        icon={props.icon} 
        color="#fff" 
        size={props.size} />
      {
        props.text ? (
          <Text style={styles.textButton}>
            {props.text}
          </Text>
        ) : (
          null
        )
      }
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  iconButton: {
    backgroundColor: '#F33',
    justifyContent: 'center',
    padding: 5,
    margin: 5,
    borderRadius: 5,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButton: {
    color: '#fff',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 14,
  },
});

export default DangerIconButton;
