import React from 'react';
import Icon from 'react-native-vector-icons/Ionicons';

const DefaultIcon = (props: any) => {
  return (
    <Icon 
      style={props.style}
      name={props.icon} 
      color={props.color} 
      size={props.size} />
  );
};

export default DefaultIcon;
